import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { InView } from 'react-intersection-observer';
import Testimonial from "../components/Testimonial"
import Clients from "../components/Clients"
import TransitionLink from "gatsby-plugin-transition-link"
import { animeFadeIn, animeFadeUp, animeSlideOutBot, animeSlideOutLeft } from "../utils/Animations"

const IndexPage = ({ data, transitionStatus}) => {

  const testimonials = [
    {
      name: 'Léa Boun, Brand Communication Specialist - Pernod Ricard France',
      text: 'Pleinement conquis par une première opération avec Belladrinks, nous avons mis en place un partenariat pour des opérations de dégustations au niveau national qui fut une totale réussite.'
    },
    {
      name: 'Sébastien Brivet, Responsable régional CHR - La Martiniquaise',
      text: 'Nous faisons appel à Belladrinks pour des opérations sur le terrain. C\'est une équipe très professionnelle, bien organisé et qui s’adapte à vos besoins.'
    },
    {
      name: 'Justine Maillard, Chargée de projets - Mondial Events',
      text: 'Super prestation bar à cocktail. L’agencement du bar est très sympa et le personnel est souriant, disponible et discret.'
    },
    {
      name: 'Tristam Mercier, Chargé de communication - LR Health & Beauty Systems',
      text: 'Prestation bar à cocktail et création de cocktails. Très professionnel et service de qualité, nous avons eu de très nombreux retours positifs de la part de nos participants.'
    }
  ]
  return (
    <Layout>
      <SEO
        title="Belladrinks - Bar à cocktails événementiel, mariage et événements professionnels"
        description="Service de bar à cocktails événementiel pour les mariages et les événements professionnels. Animez vos événements avec des cocktails et des cours de mixologie."
      />
      <section className="pt-20 min-h-screen md:min-h-3/4 relative flex flex-col justify-center items-center">
        <div className="main-container py-40 md:py-56">
          <InView triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                animate={inView ? "visible" : "hidden"}
                className="w-full lg:w-2/3"
                ref={ref}
              >
                <motion.h1 variants={animeFadeUp} className="title-header font-serif mb-8 text-white">Service de bar à cocktails pour les événements privés et professionnels</motion.h1>
                <motion.div className="inline-block" variants={animeFadeIn}>
                  <TransitionLink
                    className="button bg-white text-copper hover:bg-copper hover:text-white mr-2 md:mr-8"
                    to="/services/"
                    exit={{ length: 1 }}
                    entry={{ delay: 1 }}
                  >
                    Nos services
                  </TransitionLink>
                </motion.div>
                <motion.div className="inline-block" variants={animeFadeIn}>
                  <TransitionLink
                    className="link text-white"
                    to="/contact/"
                    exit={{ length: 1 }}
                    entry={{ delay: 1 }}
                  >
                    Contactez-nous
                  </TransitionLink>
                </motion.div>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="absolute inset-0 bg-black -z-1">
          <Img
            className="h-full opacity-70"
            fluid={data.backgroundHeader.childImageSharp.fluid}
          />
        </div>
      </section>
      <section className="py-16 md:py-36 xl:py-40 2xl:py-64">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">La passion & l'expérience du bar professionnel</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  Belladrinks propose à ses clients un service de bar à cocktails sur mesure et premium. Nos chargés de projet répondent à toutes les exigences et planifient les événements dans les moindres détails. Nos barmans sont notre meilleur atout. À chaque événement ils apportent une identité et un savoir-faire unique, héritage de leur formation dans des établissements de renom.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <InView triggerOnce={true} threshold={0.35} skip={transitionStatus !== "entered"}>
        {({ inView, ref, entry }) => (
          <motion.section
            ref={ref}
            className="w-full relative flex items-center py-24 md:py-36 xl:py-40 2xl:py-64"
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={{
              visible: {
                transition: { staggerChildren: 0.6 }
              }
            }}
          >
            <div className="main-container grid grid-cols-1 xl:grid-cols-2">
              <div className="absolute inset-0 bg-black -z-1">
                <motion.div variants={animeSlideOutBot} className="absolute inset-0 bg-pampas z-10"/>
                <Img
                  className="h-full opacity-60"
                  fluid={data.backgroundCtaServices.childImageSharp.fluid}
                />
              </div>
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 text-white">Une équipe expérimentée, <br></br> des services sur-mesures.</motion.h2>
                <motion.div variants={animeFadeIn} className="inline-block">
                  <TransitionLink
                    className="link text-white"
                    to="/services/"
                    exit={{ length: 1 }}
                    entry={{ delay: 1 }}
                  >
                    Découvrez nos services
                  </TransitionLink>
                </motion.div>
              </div>
            </div>
          </motion.section>
        )}
      </InView>
      <section className="py-20 lg:py-40">
        <InView triggerOnce={true} threshold={0.35} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              className="main-container grid grid-cols-1 md:grid-cols-12"
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              animate={inView ? "visible" : "hidden"}
            >
              <div className="row-auto h-64 order-first md:order-last md:h-auto md:row-start-1 md:col-start-5 md:col-end-13 lg:col-start-4 relative overflow-hidden">
                <motion.div variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.backgroundCocktail.childImageSharp.fluid}
                  />
                </div>
              </div>
              <div className="py-0 mt-10 md:mt-0 md:py-32 lg:py-40 row-auto md:row-start-1 md:col-start-1 md:col-end-9 lg:col-end-7 z-20">
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8">Cocktails classiques ou créations, découvrez notre sélection de cocktails.</motion.h2>
                <motion.div
                  className="inline-block"
                  variants={animeFadeIn}
                >
                  <TransitionLink
                    className="link"
                    to="/cocktails/"
                    exit={{ length: 1 }}
                    entry={{ delay: 1 }}
                  >
                    Découvrez les cocktails
                  </TransitionLink>
                </motion.div>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="relative py-20 md:py-36 lg:py-40 2xl:py-56 bg-bigstone-light">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              className="main-container"
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
            >
              <motion.h3 variants={animeFadeUp} className="display-1 mb-8 text-white">Ils nous font confiance</motion.h3>
              <motion.div variants={animeFadeIn}>
                <Testimonial testimonials={testimonials}/>
              </motion.div>
              <Clients/>
            </motion.div>
          )}
        </InView>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    backgroundHeader: file(relativePath: { eq: "header/header_contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundAbout: file(relativePath: { eq: "home_header.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    backgroundCtaServices: file(relativePath: { eq: "belladrinks-13.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
            }
        }
    }  
    backgroundCocktail: file(relativePath: { eq: "cocktails/bunnybunnyboom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
